import request from "@/utils/request";
import { listImages, deleteImage } from "./image";
export { listImages, deleteImage };

/**
 * 检查是否为管理员
 */
export function check() {
    return request({
        url: "/manage/check",
        method: "post",
    });
}
/**
 * 删除用户
 * @returns
 */
export function deleteUser(id) {
    return request({
        url: "/manage/deleteUser",
        method: "get",
        params: {
            id: id,
        },
    });
}
/**
 * 更改用户信息
 * @returns
 */
export function editUserInfo(data) {
    return request({
        url: "/manage/editUserInfo",
        method: "post",
        data: data,
    });
}
/**
 * 删除用户头像
 * @returns
 */
export function deleteUserAvatar(data) {
    return request({
        url: "/manage/deleteUserAvatar",
        method: "post",
        data: data,
    });
}

/**
 *列出用户
 * @returns
 */
export function listUser(data) {
    return request({
        url: "/manage/listUser",
        method: "post",
        data: data,
    });
}
/**
 * 审核文章
 * @returns
 */
export function articleVerify(data) {
    return request({
        url: "/manage/articleVerify",
        method: "post",
        data: data,
    });
}
/**
 * 删除文章
 * @returns
 */
export function deleteArticle(data) {
    return request({
        url: "/manage/deleteArticle",
        method: "post",
        data: data,
    });
}
/**
 *搜索文章
 * @returns
 */
export function searchArticle(data) {
    return request({
        url: "/manage/searchArticle",
        method: "post",
        data: data,
    });
}
/**
 * 审核文章评论
 * @returns
 */
export function articleCommentVerify(data) {
    return request({
        url: "/manage/articleCommentVerify",
        method: "post",
        data: data,
    });
}
/**
 * 删除文章评论
 * @returns
 */
export function deleteArticleComment(data) {
    return request({
        url: "/manage/deleteArticleComment",
        method: "post",
        data: data,
    });
}
/**
 *搜索文章评论
 * @returns
 */
export function listArticleComment(data) {
    return request({
        url: "/manage/listArticleComment",
        method: "post",
        data: data,
    });
}
/**
 *添加站点
 @returns
 */
export function addSite(data) {
    return request({
        url: "/manage/addSite",
        method: "post",
        data: data,
    });
}
/**
 *删除站点
 @returns
 */
export function deleteSite(data) {
    return request({
        url: "/manage/deleteSite",
        method: "post",
        data: data,
    });
}
/**
 *修改站点
 @returns
 */
export function updateSite(data) {
    return request({
        url: "/manage/updateSite",
        method: "post",
        data: data,
    });
}
/* 获取站点状态 */
export function getSiteStatus(id) {
    return request({
        url: "/manage/getSiteStatus",
        method: "get",
        params: {
            id: id,
        },
    });
}

/* 更新图库 */
export function updateImage() {
    return request({
        url: "/manage/updateImage",
        method: "get",
    });
}
