import request from "@/utils/request";
/**
 * 获取图库
 * @returns
 */
export function listImages(data) {
    return request({
        url: "/manage/listImages",
        method: "post",
        data: data,
    });
}
/**
 * 删除图片
 * @returns
 */
export function deleteImage(id) {
    return request({
        url: "/manage/deleteImage",
        method: "get",
        params: {
            id: id,
        },
    });
}
